import axios from "axios";
import qs from "qs";
import ReactGA from "react-ga";
import Cookies from "js-cookie";

import uploadStore from "../mobx/UploadStore";
import loaderStore from "../mobx/LoaderStore";
import shieldStore from "../mobx/ShieldStore";

// import Cookies from 'js-cookie';

export default (() => {
  const Api = {};

  Api.accessToken = null;
  const host = window.location.host;
  const isDev = window.location.host.includes("dlink");
  const isStaging = window.location.host.includes("slink");
  const isTest = window.location.host.includes("tlink");
  const isQc = window.location.host.includes("qlink");

  let server = `//${
    isDev
      ? "dapi"
      : isStaging
      ? "sapi"
      : isTest
      ? "tapi"
      : isQc
      ? "qapi"
      : "api"
  }.directcloud.jp`;
  if (host.indexOf("ismcloudone") > -1) {
    server = `//${isDev ? "dapiscs" : "apiscs"}.ismcloudone.com`;
  }

  Api.getData = (url, onSuccess, onFail) => {
    console.log("token", Cookies.get(shieldStore.linkId));
    loaderStore.setLoaded(false);
    axios
      .get(`${server}${url}`, {
        headers: {
          token: Cookies.get(shieldStore.linkId),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
          return;
        }

        if (res.data.success) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
        } else {
          loaderStore.setLoaded(true);
          onFail(res.data);
        }
      });
  };

  Api.uploadGetData = (url, onSuccess, onFail) => {
    console.log("token", Cookies.get(shieldStore.linkId));
    loaderStore.setLoaded(false);
    let api = `//uploader.directcloud.jp${url}`;
    if (isDev) {
      api = `//duploader.directcloud.jp${url}`;
    }
    if (isTest) {
      api = `//tuploader.directcloud.jp${url}`;
    }
    if (isQc) {
      api = `//quploader.directcloud.jp${url}`;
    }
    axios
      .get(api, {
        headers: {
          token: Cookies.get(shieldStore.linkId),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
          return;
        }

        if (res.data.success) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
        } else {
          loaderStore.setLoaded(true);
          onFail(res.data);
        }
      });
  };

  Api.postData = (url, params, onSuccess, onFail) => {
    loaderStore.setLoaded(false);
    axios
      .post(`${server}${url}`, qs.stringify(params), {
        headers: {
          token: Cookies.get(shieldStore.linkId) || "",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
          return;
        }

        if (res.data.success) {
          loaderStore.setLoaded(true);
          onSuccess(res.data);
        } else {
          loaderStore.setLoaded(true);
          onFail(res.data);
        }
      });
  };

  Api.postZipStatus = (url, params, onSuccess, onFail) => {
    axios.post(`${server}${url}`, qs.stringify(params)).then((res) => {
      if (res.status === 200) {
        onSuccess(res.data);
        return;
      }

      if (res.data.success) {
        onSuccess(res.data);
      } else {
        onFail(res.data);
      }
    });
  };

    Api.fileUploadNew = (url, formData, name, onSuccess, onFail, onNetworkError) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

        uploadStore.setProgress(percentCompleted);
      },
    };

    uploadStore.setProgress(0);
    let uploadServer = "//uploader.directcloud.jp";
    const isStaging = window.location.host.includes("slink");

    if (isStaging) {
      uploadServer = "//suploader.directcloud.jp";
    } else if (isDev) {
      uploadServer = "//duploader.directcloud.jp";
    } else if (isTest) {
      uploadServer = "//tuploader.directcloud.jp";
    } else if (isQc) {
      uploadServer = "//quploader.directcloud.jp";
    }

      axios.post(
        `${uploadServer}${url}`,
        formData,
        config
      ).then((res) => {
        if (res.status === 200) {
          console.log('API.FILEUPLOAD success', res);
          onSuccess(res);
        } else {
          console.log('API.FILEUPLOAD', res);
          onFail(res);
        }
      }).catch(() => {
				if (onNetworkError) {
					onNetworkError();
				}
			})
    }

  Api.fileUpload = async (url, formData, name, onSuccess, onFail) => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        uploadStore.setProgress(percentCompleted);
      },
    };

    uploadStore.setProgress(0);
    uploadStore.setFileName(name);
    await axios.post(`${server}${url}`, formData, config).then((res) => {
      if (res.data.success) {
        console.log("API.FILEUPLOAD success", res);
        onSuccess(res.data);
      } else {
        console.log("API.FILEUPLOAD", res);
        onFail(res.data);
      }
    });
  };

  Api.detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  };

  Api.detectEdge = () => {
    const ua = window.navigator.userAgent;
    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other
    return false;
  };

  Api.detectSafari = () => {
    const ua = window.navigator.userAgent;
    const safari = ua.indexOf("Safari/");
    const chrome = ua.indexOf("Chrome/");
    if (safari > 0 && chrome <= 0) {
      return parseInt(ua.substring(safari + 7, ua.indexOf(".", safari)), 10);
    }

    // other
    return false;
  };

  Api.isCloudOne = () => {
    if (!window.location.host.includes("directcloud")) {
      return true;
    }

    return false;
  };

  Api.sendGaEventLog = (buttonName) => {
    const eventId = buttonName;
    if (eventId != null) {
      ReactGA.event({
        category: `${eventId}`,
        action: "buttonClick",
      });
    }
  };

  return Api;
})();
