import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Loader from 'react-loader';

import { useTranslation } from "react-i18next";

import getFileIcon from '../../helpers/FileIcon';

import uploadStore from '../../mobx/UploadStore';

import Api from '../../api/Api';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

const FileItem = ({ data, index, style }) => {
	const { t } = useTranslation();
	const styles = {
    file: {
      borderBottom: '1px solid #eaeaea',
      padding: '8px',
      color: '#666',
    },
    img: {
      width: 24,
      height: 24,
      margin: 8,
      float: 'left',
    },
    info: {
      display:' inline-block',
      width: '85%',
      fontSize: '13px',
    },
    name: {
      display:' inline-block',
      width: '80%',
    },
    icon: {
      height: '38px',
      float: 'right',
      display:' inline-block',
      padding: '15px 12px',
      width: '20%',
    },
    p: {
      paddingLeft: '8px',
      marginBottom: '0px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    complete: {
      position: 'relative',
      top: '-8px',
      left: '24px',
    }
  }
	const item = data[index];
	const name = item.name;
	const ext = name.substring(name.lastIndexOf('.') + 1, name.length);

	const percent = item.percent === undefined ? 0 : item.percent;
	let status = '';
	if (Api.detectIE()) {
		if (item.status === 'error') {
			status = t(item.error_message);
		} else if (item.status === 'rejected_document_upload') {
			status = t('Reject uploading encrypted document')
		} else if (item.status === 'security_document_registered') {
			status = t('Security Document')
		} else if (item.status === 'security_document_encrypted') {
			status = t('Security Document')
		} else if (percent === 0) {
			status = t('Waiting');
		} else if (item.status === undefined && percent > 0 && percent < 100) {
			status = t('Uploading')
		} else if (item.status === undefined && percent === 100) {
			status = t('Processing...')
		} else if (item.status === 'complete' && percent === 100) {
			status = t('Complete');
		} else if (item.status !== undefined) {
			status = item.status;
		}
	} else {
		if (item.status === 'error') {
			status = t(item.error_message);
		} else if (item.status === 'rejected_document_upload') {
			status = t('Reject uploading encrypted document')
		} else if (item.status === 'security_document_registered') {
			status = t('Security Document')
		} else if (item.status === 'security_document_encrypted') {
			status = t('Security Document')
		} else if (percent === 0) {
			status = t('Waiting');
		} else if (item.status === undefined && percent > 0) {
			status = t('Uploading');
		} else if (item.status === 'check_virus' || item.status === 'check_shield' || (item.status === 'ready' && percent === 100)) {
			status = t('Processing...');
		} else if (item.status === 'virus_detected') {
			status = t('Virus is detected.');
		} else if (item.status === 'complete') {
			status = t('Complete');
		}
	}

	const useCompleteIcon =
		item.status === 'complete' ||
		item.status === 'security_document_registered' ||
		item.status === 'security_document_encrypted';

	const useErrorIcon =
		item.status === 'error' ||
		item.status === 'virus_detected' ||
		item.status === 'rejected_document_upload';
	return (
		<div style={style}>
			<div style={styles.file}>
        <div style={styles.name}>
          <img style={styles.img} src={getFileIcon(ext)} alt='' />
          <div className="upm__item-info" style={styles.info}>
            <p style={styles.p} title={name}>{name}</p>
            <p style={{...styles.p, color: '#bdbdbd' }} title={status}>
              {status}
            </p>
          </div>
        </div>
        <div className="upm__item-icon" style={styles.icon}>
          {
            item.status === undefined && percent > 0 && percent < 100 ? (
              <ProgressBar variant='success' now={percent} />
            ) : useCompleteIcon ? (
              <img src="/static/bundles/img/ic_complete.svg" alt="" style={styles.complete} />
            ) : useErrorIcon ? (
              <img src="/static/bundles/img/ic_warning.svg" alt="" style={styles.complete} />
            ) : (
              <div
                className="upm__item-loader"
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '-10px',
                  left: '34px',
                }}
              >
                <Loader
                  loaded={false}
                  color="#c9c9c9"
                  lines={10}
                  width={2.2}
                  radius={5}
                  length={4.5}
                />
              </div>
            )
          }
        </div>
      </div>
		</div>
	);
}

const UploadProcessModal = observer((props) => {
  const { t } = useTranslation();
  const { files, currentStatus, fileName, progress, current, count } = uploadStore;

  const styles = {
    files: {
      height: '300px',
      background: '#fff',
      overflow: 'auto',
      overflowX: 'hidden',
    },
  }

  let error = false;
  if (uploadStore.completed) {
    error = files.filter(
      item =>
        item.status === 'error' ||
        item.status === 'virus_detected' ||
        item.status === 'rejected_document_upload'
    ).length > 0;
  }

  return (
    <div className='UploadProcessModal'>
      <div className='upm__dialog'>
        <div className='upm__dialog-title'>
          <span>
            {
              `${uploadStore.completed ? t('Upload Complete') : t('Uploading')} (${current + 1}/${files.length})`
            }
          </span>
        </div>
        <div className='upm__dialog-process'>
          
          <div style={styles.files} className={`${currentStatus}`}>
						<AutoSizer>
								{({ height, width }) => (
									<List
										height={height}
										itemCount={files.length}
										itemSize={60.5}
										width={width}
										itemData={files.slice()}
										itemKey={(index, data) => {
											const item = data[index];
											return `${item.lastModified}-${item.name}`;
										}}
									>
										{FileItem}
										
									</List>
								)}
							</AutoSizer>
          </div>
          {
            !error ? (
              <p
                style={{
                  margin: '16px 8px',
                  fontSize: '12px',
                  color: uploadStore.completed ? '#4a8edc' : '#bdbdbd',
                }}
              >
                <i
                  className={`fa ${uploadStore.completed ? 'fa-check' : 'fa-warning'} mr-2`}
                  style={{
                    color: uploadStore.completed ? '#4a8edc' : '#bdbdbd',
                  }}
                />
                {
                  uploadStore.completed ?
                    t(props.completeMessage) :
                    t('Please note that upload will be stopped if you finish a browser.')
                }
              </p>
            ) : (
              <p style={{ margin: '16px 8px', fontSize: '12px', color: '#ff3200' }}>
                <i className="fa fa-warning mr-2" style={{ color: '#ff3200' }} />
                {t('There is a file failed to upload. Please check it and upload again.')}
              </p>
            )
          }
          <div style={{ height: '55px', padding: '8px', }}>
            <button
              className='upm__dialog-send'
              onClick={() => {
                props.hide(error);
              }}
              disabled={!uploadStore.completed}
            >
              <span>{t('OK')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
});

export default UploadProcessModal;
