import { observable, action, decorate } from 'mobx';
import Cookies from 'js-cookie';

import uploadStore from './UploadStore';
import shieldStore from './ShieldStore';
import Api from '../api/Api';

class WebSocketStore {
  constructor() {
    this.ws = null;
    this.isConnected = false;
    this.interval = undefined;
  }

  connectSocket = () => {
    // WebSocket
    const { protocol, host } = window.location;
    const isDev = host.includes('dlink');
    const isStaging = host.includes('slink');
    const isTest = host.includes('tlink');
    const isQc = host.includes('qlink');
    let loc = 'uploader.directcloud.jp';
    if (isStaging) {
      loc = 'suploader.directcloud.jp';
    } else if (isDev) {
      loc = 'duploader.directcloud.jp';
    } else if (isTest) {
      loc = 'tuploader.directcloud.jp';
    } else if (isQc) {
      loc = 'quploader.directcloud.jp';
    }
    let uri = 'ws:';
    if (protocol === 'https:') {
      uri = 'wss:';
    }
    uri += '//' + loc;
    uri += `/v1/ws?client_key=${uploadStore.client_key}`;

    if ((this.ws && this.ws.readyState !== WebSocket.CLOSED) || this.ws !== null) {
      return;
    }

    this.ws = new WebSocket(uri);

    this.ws.onopen = () => {
      console.log('connectSocket()', uri, uploadStore.client_key, Cookies.get(shieldStore.linkId));
      this.ws.send(`link::${Cookies.get(shieldStore.linkId)}`);
      this.isConnected = true;
    }

    this.ws.onmessage = (evt) => {
      uploadStore.setWebsocketData(JSON.parse(evt.data));
    }

    this.ws.onerror = (evt) => {
      console.log('error', evt);
      this.ws.close();
    }

    this.ws.onclose = (evt) => {
      this.ws = null;
      console.log('close', evt);
      if (this.isConnected) {
        this.sleep(1000).then(() => {
          this.ws = null;
          this.connectSocket();

          // this.getUploadStatus();
        })
      }
    }
  }

  getUploadStatus = (files, completed) => {
    if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
      return;
    }

    const lang = Cookies.get('lang');
    const url = `/v1/link/${shieldStore.linkId}/upload-status?client_key=${uploadStore.client_key}&lang=${lang}`;

    Api.uploadGetData(url, (result) => {
      console.log('getUploadStatus()', result, files);

      result.data.map(item => {
        files.map(el => {
          if (item.file_key === el.fileKey) {
            const uploadData = {
              data: {
                type: 'upload_status',
                data: {
                  file_seq: item.file_seq,
                  file_key: item.file_key,
                  node: item.node,
                  name: item.name,
                  status: completed ? 'complete' : item.status,
                }
              }
            }

            uploadStore.setWebsocketData(uploadData);
          }
        })

        if (completed) {
          uploadStore.setComplete();
        }
      });
    })
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  disconnectSocket = () => {
    console.log('disconnectSocket()');
    if (this.ws !== null) {
      this.ws.close();
      this.ws = null;
      this.isConnected = false;
    }
  }
}

decorate(WebSocketStore, {
  ws: observable,
  interval: observable,
  isConnected: observable,
  connectSocket: action,
  disconnectSocket: action,
})

const webSocketStore = new WebSocketStore();

export default webSocketStore;
export { WebSocketStore };
